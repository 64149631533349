<template>
  <div class="ask-container">
    <div class="ask-side">
      <ask-filters @update-selected-options="selectedOptions = $event" />
      <ask-results-accordion
        v-if="dev"
        :queries="queries"
        @search-result-click="handleSearchResultClick"
        @redo-query="redoQuery"
      />
    </div>
    <!-- Chat -->
    <div class="chat_container">
      <div class="messages_container" ref="message-container">
        <div v-if="!queries.length" class="message_empty">
          <h1>{{ $t('ask.title') }}<AiBadge /></h1>
          <img src="~assets/empty-states/empty-notification.svg" />
          <span>
            {{ $t('ask.description') }}
          </span>
        </div>
        <div v-for="(query, i) in queries" :key="i">
          <AskQuery
            :query="query.query"
            :markdown="query.markdown"
            :links="query.links"
            :citationLabels="query.citationLabels"
            :callouts="query.callouts"
            :loading="query.loading"
            :error="query.error"
            :id="query.queryId"
            :feedback="query.feedback"
            :infos="query.infos"
            :contexts="query.contexts"
            :dev-mode="dev"
            @feedback="handleFeedback"
            @go-to-document="goToDocument"
          />
        </div>
      </div>
      <form @submit="sendMessage" class="input_container">
        <input
          v-model="message"
          placeholder="Ask mayday..."
          required
          value=""
          @keyup.stop="handleKeyUp"
        />
        <button type="submit">
          <font-awesome-icon
            :icon="['fal', 'paper-plane']"
            class="icon-button"
          />
          {{ $t('ask.send') }}
        </button>
      </form>
    </div>
    <ask-parameters
      v-if="dev"
      @update-ask-parameters="askParameters = $event"
    />
    <ask-customization-parameters
      v-if="!dev || (askParameters && askParameters.useCustomization)"
      @update-ask-customization-parameters="customizationParameters = $event"
    >
    </ask-customization-parameters>
  </div>
</template>

<script>
import AiBadge from '@/components/AI/AiBadge.vue';
import AskCustomizationParameters from '@/components/Ask/AskCustomizationParameters.vue';
import AskFilters from '@/components/Ask/AskFilters.vue';
import AskParameters from '@/components/Ask/AskParameters.vue';
import AskQuery from '@/components/Ask/AskQuery.vue';
import AskResultsAccordion from '@/components/Ask/AskResultsAccordion.vue';
import { v4 as uuid } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Ask',
  components: {
    AiBadge,
    AskQuery,
    AskFilters,
    AskParameters,
    AskResultsAccordion,
    AskCustomizationParameters,
  },
  data: () => ({
    dev: false,
    queries: [],
    loading: false,
    options: {},
    initializing: true,
    askParameters: {},
    customizationParameters: null,
    message: '',
    currentPreviousQuery: 0,
    askLanguage: '',
    selectedOptions: {},
  }),
  async created() {
    await this.updateAskAccess(true);
    this.optionSpecs = (await this.$services.brainClient.getParameters()).data;
    if (this.userAllowAskDevPreference) this.dev = true;
    this.askLanguage = this.hasCompanyPreferenceWithValue(
      'HAS_ALL_LANGUAGES_SEARCH',
    )
      ? 'none'
      : this.editingLanguage;
  },
  methods: {
    handleFeedback(feedback, query, id) {
      this.sendAskBetaFeedback({
        queryId: id,
        queryBody: query,
        feedback: feedback,
      });
      this.queries.find((d) => d.queryId === id).feedback = feedback.rating;
    },
    handleSearchResultClick(event) {
      const document = event.message.query.search.find(
        (d) => d.id === event.id,
      );
      this.goToDocument(document);
    },
    redoQuery(queryId) {
      const queryToRedo = this.queries.find((d) => d.queryId === queryId);
      const newQueryId = this.getCorrelationId();

      const currentMessageIndex = this.queries.length;
      this.queries.push({
        response: '',
        query: queryToRedo.query,
        createdAt: new Date(),
        loading: true,
        queryId: newQueryId,
        markdown: '',
        links: [],
        infos: {
          requestTime: Date.now(),
          firstChunkTime: null,
          lastChunkTime: null,
          firstTextChunkTime: null,
        },
        feedback: null,
        citationLabels: {},
      });

      this.fetchStream(queryId, currentMessageIndex);
    },
    sendMessage(event) {
      this.currentPreviousQuery = 0;
      event.preventDefault();

      const queryId = this.getCorrelationId();
      const currentMessageIndex = this.queries.length;
      const conversation = this.askParameters.useHistory
        ? this.queries.map((message) => [
            message.query.queryText,
            message.markdown,
          ])
        : null;
      this.queries.push({
        response: '',
        query: {
          queryText: this.message,
          ...this.options,
          ...this.askParameters,
          customization: this.hasCustomization
            ? this.customizationParameters
            : null,
          pre: this.selectedOptions,
          language: this.askLanguage,
          conversation,
        },
        createdAt: new Date(),
        loading: true,
        queryId: queryId,
        markdown: '',
        links: [],
        infos: {
          requestTime: Date.now(),
          firstChunkTime: null,
          lastChunkTime: null,
          firstTextChunkTime: null,
        },
        feedback: null,
        citationLabels: {},
      });

      this.fetchStream(queryId, currentMessageIndex);

      // EVENT
      this.$services.events.ask.question({
        queryText: this.message,
        queryId,
        index: currentMessageIndex,
        source: 'beta',
        trigger: 'question',
      });
      this.message = '';
      event.target.reset();
    },
    getCorrelationId() {
      return 'web-' + uuid();
    },
    async fetchStream(queryId, currentMessageIndex) {
      this.loading = true;
      const currentMessage = this.queries.at(currentMessageIndex);
      try {
        for await (const chunk of await this.fetchAsk({
          queryId,
          queryBody: currentMessage.query,
          dev: this.dev,
          askInterface: this.dev ? 'beta-dev' : 'beta',
        })) {
          if (currentMessage.infos.firstChunkTime === null) {
            currentMessage.infos.firstChunkTime = Date.now();
          }

          this.parseResultChunk(chunk, currentMessageIndex);
        }
        this.loading = false;
        if (currentMessage.infos.lastChunkTime === null) {
          currentMessage.infos.lastChunkTime = Date.now();
        }
        this.$services.events.ask.answer({
          queryId: currentMessage.queryId,
          index: currentMessageIndex,
          answer: currentMessage.markdown,
          queryText: currentMessage.query.queryText,
          answerType: currentMessage.answerType,
          links: this.linkIds(currentMessage),
          infos: currentMessage.infos ? { ...currentMessage.infos } : null,
          source: this.dev ? 'beta-dev' : 'beta',
          trigger: 'question',
        });
      } catch (e) {
        this.$message.error(e);
        currentMessage.error = {
          message: e.message,
        };

        this.loading = false;
        this.$services.events.ask.error({
          queryId: currentMessage.queryId,
          index: currentMessageIndex,
          answer: currentMessage.markdown,
          links: this.linkIds(currentMessage),
          infos: currentMessage.infos ? { ...currentMessage.infos } : null,
          errorMessage: currentMessage.error.message,
          source: this.dev ? 'beta-dev' : 'beta',
          trigger: 'question',
        });
      } finally {
        this.loading = false;
      }
    },
    parseResultChunk(chunk, currentMessageIndex) {
      const currentMessage = this.queries.at(currentMessageIndex);
      if (chunk.type === 'full-answer') {
        currentMessage.response = '';
        currentMessage.markdown = '';
        currentMessage.citationLabels = {};
      }
      if (chunk.type === 'answer-type') {
        currentMessage.answerType = chunk.content;
      }
      if (chunk.type === 'answer' || chunk.type === 'full-answer') {
        if (currentMessage.infos.firstTextChunkTime === null) {
          currentMessage.infos.firstTextChunkTime = Date.now();
        }
        currentMessage.response += chunk.content.text;

        for (let val of chunk.content.chunks) {
          if (!currentMessage.markdown) {
            currentMessage.markdown += val.text;
            continue;
          }
          if (val.link) {
            if (!currentMessage.citationLabels[val.link]) {
              currentMessage.citationLabels = {
                ...currentMessage.citationLabels,
                [val.link]:
                  Object.keys(currentMessage.citationLabels).length + 1,
              };
            }
            const citationLabel = currentMessage.citationLabels[val.link];
            if (val.quote) {
              currentMessage.markdown += `[quote://${val.text}](${val.link})`;
            } else {
              currentMessage.markdown += `[${citationLabel}](${val.link})`;
            }
          } else {
            currentMessage.markdown += val.text;
          }
        }
        currentMessage.loading = false;
      } else if (chunk.type === 'search') {
        currentMessage.query.search = this.parseSearchResults(
          chunk.content.documents,
        );
      } else if (chunk.type === 'targeted-keywords') {
        currentMessage.query.keywords = chunk.content;
      } else if (chunk.type === 'documents') {
        currentMessage.links = chunk.content;
      } else if (chunk.type === 'language') {
        currentMessage.query.language = chunk.content;
      } else if (chunk.type === 'ask-processing-results') {
        currentMessage.contexts = Object.keys(chunk.content).reduce(
          (acc, key) => {
            acc[key] = chunk.content[key].summary.accepted
              ? chunk.content[key].summary.contexts.join('\n')
              : '';
            return acc;
          },
          {},
        );
        currentMessage.callouts = Object.keys(chunk.content).reduce(
          (acc, key) => {
            if (!chunk.content[key].summary.accepted) return acc;
            if (!chunk.content[key].callouts) return acc;
            acc[key] = {
              summaries: chunk.content[key].callouts.notes
                ? Object.keys(chunk.content[key].callouts.notes).map(
                    (level) =>
                      `${level}: ${chunk.content[key].callouts.notes[level].content}`,
                  )
                : '',
              counts: chunk.content[key].callouts.callouts
                ? chunk.content[key].callouts.callouts.reduce((acc, val) => {
                    if (acc[val.level] == undefined) acc[val.level] = 1;
                    else acc[val.level] += 1;
                    return acc;
                  }, {})
                : {},
            };
            return acc;
          },
          {},
        );
      } else if (
        chunk.type === 'answer-type' &&
        chunk.content === 'inappropriate'
      ) {
        currentMessage.markdown = this.$t('ask.search.inappropriate');
        currentMessage.loading = false;
      } else if (chunk.type === 'error') {
        currentMessage.content = chunk.content;
        currentMessage.loading = false;
        currentMessage.error = {
          message: chunk.content,
        };
        throw new Error(chunk.content.message);
      } else {
        console.warn('Unsupported message type', chunk);
      }
    },
    getDocLabel(doc) {
      const navigationLanguage =
        this.$store.state.knowledgeModule.navigationLanguage;
      if (doc.translations[navigationLanguage]) {
        return doc.translations[navigationLanguage].label;
      } else {
        return doc.translations[doc.defaultLanguage].label;
      }
    },
    parseSearchResults(documents) {
      const linkedDocuments = [];
      if (documents) {
        documents.forEach((linkedDocument) => {
          if (!linkedDocument) return;
          linkedDocuments.push({
            score: linkedDocument.score,
            entity: linkedDocument.entity,
            label: this.getDocLabel(linkedDocument.doc),
            id: linkedDocument.doc.id,
            type: linkedDocument.doc.type,
            ancestors: linkedDocument.doc.ancestors,
            //breadcrumbs: this.parseBreadcrumbs(linkedDocument.doc),
          });
        });
      }
      return linkedDocuments;
    },
    goToDocument(doc) {
      const documentType = 'content'; //doc.type.toLowerCase();
      const path = `/knowledge/${documentType}/`;

      let subPath = doc.id;
      if (
        documentType === 'content' &&
        ['Step', 'keyStep'].includes(doc.type)
      ) {
        subPath = `${doc.ancestors[0].split('/')[0]}/step/${doc.id}`;
      }

      window.open(`${window.location.origin}${path}${subPath}`, '_blank');
    },
    handleKeyUp(event) {
      if (event.keyCode === 38) {
        event.preventDefault();
        this.currentPreviousQuery = Math.max(
          this.currentPreviousQuery - 1,
          -this.queries.length,
        );
        this.fillWithPreviousQuery();
      } else if (event.keyCode === 40) {
        event.preventDefault();
        this.currentPreviousQuery = Math.min(this.currentPreviousQuery + 1, 0);
        this.fillWithPreviousQuery();
      } else {
        this.currentPreviousQuery = 0;
      }
    },
    fillWithPreviousQuery() {
      if (
        this.currentPreviousQuery < 0 &&
        this.queries &&
        this.queries.at(this.currentPreviousQuery)
      ) {
        this.message = this.queries.at(
          this.currentPreviousQuery,
        ).query.queryText;
      }
    },
    linkIds(currentMessage) {
      return currentMessage.links
        ? [...currentMessage.links.map((link) => link.id)]
        : null;
    },
    ...mapActions('brainModule', ['fetchAsk', 'sendAskBetaFeedback']),
    ...mapActions(['updateAskAccess']),
  },
  computed: {
    defaultLanguage() {
      return this.$store.state.knowledgeModule.navigationLanguage;
    },
    hasCustomization() {
      return (
        this.customizationParameters &&
        (this.customizationParameters.persona ||
          this.customizationParameters.tone.length ||
          this.customizationParameters.formatting.length)
      );
    },
    ...mapGetters([
      'isParametric',
      'hasCompanyPreferenceWithValue',
      'userAllowAskDevPreference',
    ]),
    ...mapGetters('knowledgeModule', ['editingLanguage']),
  },
  watch: {
    queries() {
      //smooth scroll to bottom of messages
      this.$nextTick(() => {
        this.$refs['message-container'].scrollTop =
          this.$refs['message-container'].scrollHeight;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ask-container {
  display: flex !important;
  height: 100vh;
  width: 100%;
}

.chat_container {
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin: 0 auto 0 0;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(40, 40, 40, 0.1);
}

.messages_container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.message_empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 0 40px;

  h1 {
    display: flex;
  }

  span {
    white-space: pre-wrap;
  }
}

.message {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  max-width: 60%;
  width: fit-content;

  &.question {
    align-self: flex-end;
    background-color: $purple-5-mayday;
    color: white;
    animation: question-enter 0.4s ease-in-out;
  }

  &.answer {
    align-self: flex-start;
    background-color: $grey-4-mayday;
    animation: answer-enter 0.4s ease-in-out;
  }

  &.error {
    align-self: flex-start;
    background-color: rgba($red-mayday, 0.75);
    animation: answer-enter 0.4s ease-in-out;
    color: white;
  }
}

.error_details {
  background-color: rgba($grey-4-mayday, 0.5);
  border-radius: 4px;
  padding: 8px;
  list-style: none;
  cursor: pointer;
}

.error_details summary {
  font-size: 12px;
  list-style: none;
  cursor: pointer;
}

.error_traceback {
  border-radius: 4px;
  font-size: 0.7em;
  padding: 12px;
  background-color: rgba($grey-4-mayday, 0.5);
  margin-top: 4px;
  margin-bottom: 0px;
}

.citation_list {
  background-color: rgba(white, 0.4);
  border-radius: 4px;
  padding: 8px;
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(white, 0.2);
  }
}

.citation_list summary {
  list-style: none;
  cursor: pointer;
}

.message_author {
  font-weight: bold;
  font-size: 0.7em;
  text-align: right;
}

.message_date {
  font-size: 0.5em;
  text-align: right;
}

.search-result-button {
  display: flex;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 8px;
  border-radius: 4px;
  flex: none;
  padding: 4px;
  border: solid 1px $purple-5-mayday;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: white;
    color: $blue-5-mayday;
    cursor: pointer;
  }
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }
}

.search_item {
  padding: 6px 12px;
  margin-bottom: 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $grey-2-mayday;
}
.score-text {
  color: $grey-6-mayday;
  font-size: 10px;
}

.context-str {
  border: solid 1px $grey-6-mayday;
  border-radius: 4px;
  padding: 4px;
}

.input_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  input {
    width: 100%;
    padding: 10px;
    margin-right: 8px;
    border: 1px solid $grey-2-mayday;
    border-radius: 5px;
  }

  .icon-button {
    margin-right: 4px;
  }

  button {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }
}
.el-tag a {
  color: $grey-2-mayday;
}

@keyframes answer-enter {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes question-enter {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.message_feedback {
  display: flex;
}

.thumbs-icon {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey-8-mayday;
  height: 24px;
  width: 24px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: $grey-3-mayday !important;
  }
  &:active {
    background-color: $grey-4-mayday !important;
  }
}

.ask-side {
  width: 20%;
  display: flex;
  flex-direction: column;
}
</style>
