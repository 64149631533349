var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.element.label === 'language' && _vm.multilingualLoader),expression:"element.label === 'language' && multilingualLoader"}]},[(_vm.isDisplay)?_c('div',{class:[
      'element',
      {
        'horizontal-element': _vm.element.inputType === 'boolean',
      },
    ]},[_c('div',{class:{ 'horizontal-element': _vm.element.inputType === 'collapse' }},[_c('div',[_c('span',{staticClass:"element-title"},[_vm._v(_vm._s(typeof _vm.element.title === 'object' ? _vm.$t(_vm.element.title.text) + _vm.$t(`knowledge.languages.${_vm.element.title.param}`) : _vm.$t(_vm.element.title)))]),_c('div',{staticClass:"element-description"},[_vm._v(_vm._s(_vm.$t(_vm.element.description)))])]),(_vm.element.label === 'language')?_c('div',[_c('el-switch',{attrs:{"disabled":!_vm.element.isMonolingual},on:{"change":function($event){_vm.modals.newLanguage = true}},model:{value:(_vm.languageSwitch),callback:function ($$v) {_vm.languageSwitch=$$v},expression:"languageSwitch"}})],1):_vm._e()]),(!_vm.element.isMonolingual)?_c('div',{staticClass:"input"},[(_vm.element.inputType === 'collapse')?_c('div',{staticClass:"add-button my-2"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.modals.newLanguage = true}}},[_vm._v(" "+_vm._s(_vm.$t('public-knowledge.configuration.items.language.add-language'))+" ")])],1):_vm._e(),_c(_vm.element.component,{tag:"component",attrs:{"elementValueDisplay":_vm.elementValueDisplay,"element":_vm.element,"isEmpty":_vm.isEmpty},on:{"hover":function($event){_vm.hover = $event},"new-element":_vm.newElement,"delete-element":_vm.deleteElement,"new-operation":function($event){return _vm.$emit('new-operation', $event)}}})],1):_vm._e()]):_vm._e(),(_vm.element.inputType === 'boolean' && _vm.element.activeMessage)?_c('div',{staticClass:"state-info"},[_c('font-awesome-icon',{class:[
        'icon-state-info mr-1',
        {
          'active-green':
            _vm.elementValueDisplay === 'true' && !_vm.element.activeColorIsYellow,
        },
        {
          'active-yellow':
            _vm.elementValueDisplay === 'true' && _vm.element.activeColorIsYellow,
        },
      ],attrs:{"icon":_vm.activeIcon}}),_vm._v(" "+_vm._s(_vm.elementValueDisplay === 'true' ? _vm.$t(_vm.element.activeMessage) : _vm.$t(_vm.element.disableMessage))+" ")],1):_vm._e(),(_vm.element.changingElements && _vm.elementValueDisplay === 'true')?_c('div',{staticClass:"subItem mt-3"},_vm._l((_vm.element.changingElements),function(childElement){return _c('public-knowledge-element',{key:childElement.label,staticClass:"mb-3",attrs:{"element":childElement},on:{"new-operation":function($event){return _vm.$emit('new-operation', $event)}}})}),1):_vm._e(),(_vm.modals.newLanguage)?_c('NewLanguageModal',{attrs:{"display":_vm.modals.newLanguage,"isMonolingual":_vm.element.isMonolingual,"existingLanguages":_vm.element.supportedLanguages},on:{"close":function($event){_vm.modals.newLanguage = false},"new-language":function($event){return _vm.$emit('new-language', $event)},"set-multilingual":function($event){return _vm.$emit('set-multilingual', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }