<template>
  <div class="options-container">
    <div class="options">
      <div class="option">
        <div class="section_title">Customization</div>
      </div>
      <div class="option">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Current" name="current"
            ><div v-if="currentCustomization">
              <span class="analyze_label">
                {{ $t('ask.customize.persona') }}
              </span>
              <span>
                {{ currentCustomization.persona }}
              </span>
              <br />
              <span class="analyze_label">
                {{ $t('ask.customize.tone') }}
              </span>
              <ul>
                <li
                  v-for="(t, index) in currentCustomization.tone"
                  :key="index"
                >
                  {{ t }}
                </li>
              </ul>
              <br />
              <span class="analyze_label">
                {{ $t('ask.customize.formatting') }}
              </span>
              <ul>
                <li
                  v-for="(f, index) in currentCustomization.formatting"
                  :key="index"
                >
                  {{ f }}
                </li>
              </ul>
              <button @click="deleteCurrentCustomization">Delete</button>
            </div>
            <div v-else>
              <span> No customization </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="New" name="new">
            <textarea
              v-model="textSpec"
              resize="none"
              ref="textarea"
              :rows="5"
            ></textarea>
            <button
              @click="updateCustomizationParameters"
              :disabled="!textSpec || analyzeText !== 'Analyze'"
            >
              <font-awesome-icon :icon="['fa', 'sparkles']" />
              {{ analyzeText }}
            </button>
            <el-divider />
            <div v-if="isError">
              {{ $t('ask.customize.inappropriate') }}
            </div>
            <div v-else-if="hasAnalyzedFacets">
              <span class="analyze_label">
                {{ $t('ask.customize.persona') }}
              </span>
              <span>
                {{ persona }}
              </span>
              <br />
              <span class="analyze_label">
                {{ $t('ask.customize.tone') }}
              </span>
              <ul>
                <li v-for="(t, index) in tone" :key="index">
                  {{ t }}
                </li>
              </ul>
              <br />
              <span class="analyze_label">
                {{ $t('ask.customize.formatting') }}
              </span>
              <ul>
                <li v-for="(f, index) in formatting" :key="index">
                  {{ f }}
                </li>
              </ul>
              <button @click="saveCustomization">{{ saveText }}</button>
            </div></el-tab-pane
          >
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ask-customization-parameters',
  data: () => ({
    activeTab: 'current',
    textSpec: '',
    persona: '',
    tone: [],
    formatting: [],
    isError: false,
    currentCustomization: null,
    saveText: 'Save',
    analyzeText: 'Analyze',
  }),
  methods: {
    ...mapActions('brainModule', [
      'analyzeCustomizationParameters',
      'setCustomization',
      'getCustomization',
      'delCustomization',
    ]),
    async updateCustomizationParameters() {
      try {
        this.analyzeText = 'Analyzing...';
        const { data } = await this.analyzeCustomizationParameters({
          textSpec: this.textSpec,
        });
        this.persona = data.persona;
        this.tone = data.tone;
        this.formatting = data.formatting;
        this.$emit('update-ask-customization-parameters', {
          persona: this.persona,
          tone: this.tone,
          formatting: this.formatting,
        });
        this.isError = false;
        this.analyzeText = 'Done';
        setTimeout(() => {
          this.analyzeText = 'Analyze';
        }, 500);
      } catch (error) {
        this.$emit('update-ask-customization-parameters', {});
        this.isError = true;
        return;
      }
    },
    async fetchCurrentCustomization() {
      try {
        const { data } = await this.getCustomization({});
        this.currentCustomization = data;
      } catch (error) {
        this.currentCustomization = null;
      }
    },
    async resetCustomization() {
      this.persona = '';
      this.tone = [];
      this.formatting = [];
      this.textSpec = '';
      this.$emit('update-ask-customization-parameters', {
        persona: this.persona,
        tone: this.tone,
        formatting: this.formatting,
      });
    },
    async saveCustomization() {
      this.saveText = 'Saving...';
      await this.setCustomization({
        customization: {
          persona: this.persona,
          tone: this.tone,
          formatting: this.formatting,
        },
      });
      this.currentCustomization = {
        persona: this.persona,
        tone: this.tone,
        formatting: this.formatting,
      };
      this.saveText = 'Saved!';
      setTimeout(() => {
        this.saveText = 'Save';
        this.resetCustomization();
        this.activeTab = 'current';
      }, 500);
    },
    async deleteCurrentCustomization() {
      await this.delCustomization({});
      this.currentCustomization = null;
    },
    async toggleCustomization(event) {
      this.enableCustomization = event;
      if (!this.enableCustomization) {
        await this.resetCustomization();
      } else {
        await this.fetchCurrentCustomization();
      }
    },
  },
  watch: {
    activeTab(newTab) {
      if (newTab === 'current') {
        this.resetCustomization();
      }
    },
  },
  computed: {
    hasAnalyzedFacets() {
      return this.persona || this.tone.length || this.formatting.length;
    },
  },
  async mounted() {
    await this.fetchCurrentCustomization();
  },
};
</script>

<style scoped lang="scss">
.options-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .options {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .section_title {
      font-size: 18px;
      font-weight: 700;
      color: $grey-8-mayday;
      border-top: 1px solid lightgray;
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      .option_content {
        display: flex;
        flex-direction: column;
        select {
          padding: 5px;
          border: 1px solid $grey-2-mayday;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          background-color: white;
        }
      }
    }
  }
}

textarea {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }

  &:disabled {
    border: 1px solid $purple-4-mayday;
    background-color: $purple-4-mayday;
  }
}

.analyze_label {
  font-size: 18px;
  font-weight: 700;
  color: $grey-8-mayday;
}
</style>
