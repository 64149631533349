<template>
  <div v-loading="element.label === 'language' && multilingualLoader">
    <div
      :class="[
        'element',
        {
          'horizontal-element': element.inputType === 'boolean',
        },
      ]"
      v-if="isDisplay"
    >
      <div :class="{ 'horizontal-element': element.inputType === 'collapse' }">
        <div>
          <span class="element-title">{{
            typeof element.title === 'object'
              ? $t(element.title.text) +
                $t(`knowledge.languages.${element.title.param}`)
              : $t(element.title)
          }}</span>
          <div class="element-description">{{ $t(element.description) }}</div>
        </div>
        <div v-if="element.label === 'language'">
          <el-switch
            :disabled="!element.isMonolingual"
            v-model="languageSwitch"
            @change="modals.newLanguage = true"
          ></el-switch>
        </div>
      </div>

      <div class="input" v-if="!element.isMonolingual">
        <div class="add-button my-2" v-if="element.inputType === 'collapse'">
          <el-button
            size="mini"
            @click="modals.newLanguage = true"
            type="primary"
          >
            {{
              $t('public-knowledge.configuration.items.language.add-language')
            }}
          </el-button>
        </div>
        <component
          :is="element.component"
          :elementValueDisplay="elementValueDisplay"
          :element="element"
          :isEmpty="isEmpty"
          @hover="hover = $event"
          @new-element="newElement"
          @delete-element="deleteElement"
          @new-operation="$emit('new-operation', $event)"
        />
      </div>
    </div>
    <div
      v-if="element.inputType === 'boolean' && element.activeMessage"
      class="state-info"
    >
      <font-awesome-icon
        :icon="activeIcon"
        :class="[
          'icon-state-info mr-1',
          {
            'active-green':
              elementValueDisplay === 'true' && !element.activeColorIsYellow,
          },
          {
            'active-yellow':
              elementValueDisplay === 'true' && element.activeColorIsYellow,
          },
        ]"
      ></font-awesome-icon>
      {{
        elementValueDisplay === 'true'
          ? $t(element.activeMessage)
          : $t(element.disableMessage)
      }}
    </div>
    <div
      v-if="element.changingElements && elementValueDisplay === 'true'"
      class="subItem mt-3"
    >
      <public-knowledge-element
        v-for="childElement in element.changingElements"
        :key="childElement.label"
        :element="childElement"
        class="mb-3"
        @new-operation="$emit('new-operation', $event)"
      />
    </div>
    <NewLanguageModal
      v-if="modals.newLanguage"
      :display="modals.newLanguage"
      :isMonolingual="element.isMonolingual"
      :existingLanguages="element.supportedLanguages"
      @close="modals.newLanguage = false"
      @new-language="$emit('new-language', $event)"
      @set-multilingual="$emit('set-multilingual', $event)"
    ></NewLanguageModal>
  </div>
</template>

<script>
import NewLanguageModal from './commons/NewLanguageModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'public-knowledge-element',
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    NewLanguageModal,
    ImageInput: () => import('./commons/ImageInput'),
    SliderInput: () => import('./commons/SliderInput'),
    ColorInput: () => import('./commons/ColorInput'),
    TextInput: () => import('./commons/TextInput'),
    TextCopyInput: () => import('./commons/TextCopyInput'),
    BooleanInput: () => import('./commons/BooleanInput'),
    SelectInput: () => import('./commons/SelectInput'),
    TextAreaInput: () => import('./commons/TextAreaInput'),
    CollapseInput: () => import('./commons/CollapseInput'),
    // CodeAreaInput: () => import('./commons/CodeAreaInput'),
    FileInput: () => import('./commons/FileInput'),
  },
  data() {
    return {
      hover: false,
      isEmpty: true,
      modals: {
        newLanguage: false,
      },
      languageSwitch: false,
    };
  },
  computed: {
    elementValueDisplay() {
      if (this.element.value) return this.element.value;
      if (this.element.fallback) return this.element.fallback;
      if (this.hover) return this.element.emptyPlaceholderHover;
      else return this.element.emptyPlaceholder;
    },
    isDisplay() {
      if (!this.element.display) return true;

      return this.getCompanyPreference(this.element.display);
    },
    activeIcon() {
      return this.element.activeIcon || ['fas', 'check-circle'];
    },

    ...mapGetters('publicKnowledgeModule', [
      'multilingualLoader',
      'focusKnowledge',
    ]),
    ...mapGetters(['getCompanyPreference']),
  },
  created() {
    if (this.element.label === 'language') {
      this.languageSwitch = !this.element.isMonolingual;
    }
  },
  methods: {
    newElement(e) {
      if (typeof e == 'object' && e !== null) {
        return this.$emit('new-operation', {
          key: e.subpath,
          value: e.value,
          path: [this.element.path, e.subpath].join('.'),
          operationType: 'update',
          inputType: this.element.inputType,
          direction: this.element.direction,
        });
      }

      return this.$emit('new-operation', {
        key: this.element.label,
        value: e,
        path: this.element.path,
        operationType: 'update',
        inputType: this.element.inputType,
        direction: this.element.direction,
      });
    },
    deleteElement() {
      this.$emit('new-operation', {
        key: this.element.label,
        path: this.element.path,
        operationType: 'delete',
        inputType: this.element.inputType,
        direction: this.element.direction,
      });
    },
  },
  watch: {
    elementValueDisplay: {
      handler() {
        if (this.element.value) this.isEmpty = false;
        else this.isEmpty = true;
      },
      immediate: true,
    },
    'modals.newLanguage': function (val) {
      if (
        this.element.label === 'language' &&
        !val &&
        !this.multilingualLoader
      ) {
        this.languageSwitch = !this.element.isMonolingual;
      }
    },
    multilingualLoader() {
      if (!this.multilingualLoader) {
        this.languageSwitch = !this.element.isMonolingual;
      }
    },
    focusKnowledge() {
      if (this.element.label === 'language') {
        this.languageSwitch = !this.element.isMonolingual;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.element:hover {
  :deep() .display-on-hover {
    display: block;
  }
}
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.horizontal-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.state-info {
  font-size: 12px;
}

.icon-state-info {
  color: $grey-4-mayday;
}

.active-green {
  color: $green-mayday !important;
}
.active-yellow {
  color: $yellow-mayday !important;
}

.subItem {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
  min-width: 600px;
}
</style>
