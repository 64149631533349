<template>
  <div class="d-flex flex-row align-items-center mt-2">
    <el-input
      :placeholder="$t(element.emptyPlaceholder)"
      v-model="textValue"
      size="mini"
      class="text-input mr-2"
      :disabled="!isEmpty && !isModifying"
      :style="`width : ${element.width}`"
    ></el-input>
    <el-button v-if="isModifying" size="mini" @click="newValue" type="primary">
      {{ $t('public-knowledge.configuration.items.domain.save-button') }}
    </el-button>
    <el-button v-else size="mini" @click="isModifying = true" type="primary">
      {{ $t('public-knowledge.configuration.items.domain.modify-button') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  data() {
    return {
      textValue: '',
      isModifying: false,
    };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  created() {
    this.isModifying = this.isEmpty;
    if (!this.isEmpty) {
      this.textValue = this.element.renderMethod
        ? this.element.renderMethod(this.elementValueDisplay)
        : this.elementValueDisplay;
    }
  },
  methods: {
    async newValue() {
      let toSend = this.element.formatMethod
        ? this.element.formatMethod(this.textValue)
        : this.textValue;
      if (typeof toSend === 'object') {
        toSend = await this.$store.dispatch(toSend.storeAction, toSend.value);
      }
      if (
        typeof toSend === 'object' &&
        this.$store.getters[`publicKnowledgeModule/${toSend.errorKey}`]
      ) {
        return this.$notify({
          type: 'error',
          title: this.$t(
            'public-knowledge.configuration.items.domain.custom-hostname-error.title',
          ),
          message: this.$t(
            `public-knowledge.configuration.items.domain.custom-hostname-error.${
              this.$store.getters[`publicKnowledgeModule/${toSend.errorKey}`]
                .message
            }`,
            {
              error:
                this.$store.getters[`publicKnowledgeModule/${toSend.errorKey}`]
                  .message,
            },
          ),
          closable: true,
          customClass: 'custom-notification custom-notification-info',
          duration: 0,
        });
      }
      this.handleToSend(toSend);
    },
    handleToSend(toSend) {
      if (toSend || toSend === '') {
        this.$emit('new-element', toSend);
        this.isModifying = toSend === '' ? true : false;
        this.notifyRegistered(true);
      } else {
        this.notifyRegistered(false);
      }
    },
    notifyRegistered(done) {
      this.$message({
        message: this.$t(
          `${
            done
              ? 'public-knowledge.registered-confirmation-message'
              : 'public-knowledge.not-registered-confirmation-message'
          }`,
        ),
        type: `${done ? 'success' : 'fail'}`,
      });
    },
  },
  watch: {
    elementValueDisplay() {
      if (this.isEmpty) this.textValue = '';
      else
        this.textValue = this.element.renderMethod
          ? this.element.renderMethod(this.elementValueDisplay)
          : this.elementValueDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  font-size: 12px;
}
:deep() .el-input.is-disabled .el-input__inner {
  color: $grey-7-mayday;
}
</style>
