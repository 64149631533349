<template>
  <div>
    <div
      v-if="verified"
      class="card bg-secondary shadow border-0"
      id="invitation-box"
    >
      <div class="card-body px-md-5 py-md-5 pb-md-3">
        <div class="text-center text-muted mb-4">
          <h1>{{ $t('landing.form.register-context') }}</h1>
        </div>
        <RegisterForm
          ref="form"
          @submit="createUserFromInvitation"
          :isRegisterLoading="isRegisterLoading"
          :alert="alert"
          :email="user.email"
          :company="user.company"
          :disable-email-validation="true"
        ></RegisterForm>
      </div>
    </div>
    <div>{{ err }}</div>
  </div>
</template>

<script>
import RegisterForm from './RegisterForm/RegisterForm';

export default {
  name: 'UserInvitation',
  components: {
    RegisterForm,
  },
  data() {
    return {
      verified: false,
      err: null,
      checkboxes: {
        unchecked: false,
      },
      token: {
        value: '',
        type: '',
      },
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        company: '',
      },
      isRegisterLoading: false,
      alert: {
        message: '',
        type: '',
      },
    };
  },
  async mounted() {
    const { token, tokenType } = this.$route.query;
    this.token = { value: token, tokenType };

    if (!token && !tokenType)
      return this.$router.push('/error?message=INVALID_TOKEN');

    const user = await this.$services.users.verifyToken({
      token,
      tokenType,
    });

    if (!user || !user.id)
      return this.$router.push('/error?message=INVALID_TOKEN');

    this.user = user;
    this.user.company = user.company.name;
    this.user.password = '';
    this.verified = true;
  },
  methods: {
    async createUserFromInvitation({ firstName, lastName, email, password }) {
      this.isRegisterLoading = true;
      const procFirstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const procLastName = lastName.toUpperCase();
      const username = `${procFirstName} ${procLastName}`;
      await this.$services.users.createUserFromInvitation({
        username,
        email,
        password,
        token: this.token.value,
        tokenType: this.token.tokenType,
      });
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
#invitation-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.text-underline {
  text-decoration: underline;
}

.dark-link {
  color: #00407c;
}

.dark-link:hover {
  text-decoration: none;
}
</style>
