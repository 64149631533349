<template>
  <div class="callouts" v-if="calloutSummaries.length > 0">
    <div class="callouts-header">
      <span>{{
        $tc('ask.callouts.counts.found-pre', totalCalloutsCount)
      }}</span>
      <span>{{ '\xa0' }}</span>
      <span>{{ calloutCountsString }}</span>
    </div>
    <div class="callouts-content">
      <ul>
        <template v-for="callout in calloutSummaries">
          <li v-for="(summary, i) in callout.summaries" :key="`${callout.documentId}:${i}`">
            <span
              :class="['link', { hovered: hovered === callout.documentId }]"
              @mouseover="() => emitLinkMouseOver(callout.documentId)"
              @mouseleave="() => emitLinkMouseLeave(callout.documentId)"
            >
              {{ summary }}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
const CALLOUTS_ORDERING = ['error', 'danger', 'warning', 'success', 'info'];

export default {
  name: 'AskCallouts',
  props: {
    callouts: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    hovered: {
      type: String,
      default: '',
    },
    citedIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    calloutSummaries() {
      return Object.keys(this.callouts)
        .filter((documentId) => this.citedIds.includes(documentId))
        .map((documentId) => ({
          documentId,
          summaries: this.callouts[documentId].summaries,
        }))
        .filter((d) => d.summaries);
    },
    calloutCounts() {
      let counts = {
        error: 0,
        danger: 0,
        warning: 0,
        success: 0,
        info: 0,
      };
      for (let documentId of this.citedIds) {
        if (this.callouts[documentId]) {
          Object.keys(this.callouts[documentId].counts).forEach((level) => {
            counts[level] += this.callouts[documentId].counts[level];
          });
        }
      }
      return counts;
    },
    totalCalloutsCount() {
      return CALLOUTS_ORDERING.map((level) => this.calloutCounts[level]).reduce(
        (a, b) => a + b,
        0,
      );
    },
    calloutCountsString() {
      const calloutsStrings = CALLOUTS_ORDERING.filter(
        (level) => this.calloutCounts[level],
      ).map((level) =>
        this.$tc(`ask.callouts.counts.${level}`, this.calloutCounts[level]),
      );
      if (calloutsStrings.length == 1) {
        return calloutsStrings[0];
      } else {
        return (
          calloutsStrings.slice(0, -1).join(', ') +
          ` ${this.$t('ask.callouts.counts.and')} ` +
          calloutsStrings.slice(-1)
        );
      }
    },
  },
  methods: {
    emitLinkMouseOver(documentId) {
      this.$emit('md-event', { id: documentId, type: 'hover' });
    },
    emitLinkMouseLeave(documentId) {
      this.$emit('md-event', { id: documentId, type: 'leave' });
    },
  },
};
</script>

<style scoped lang="scss">
.link {
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  &.hovered,
  &:hover {
    background-color: $purple-5-mayday;
    color: white;
  }
}
.callouts-header {
  background: $purple-5-mayday;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 16px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.callouts-content {
  background: $purple-1-mayday;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 8px;
}
</style>
